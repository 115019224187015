/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../header/header"
import { ToastContainer } from "react-toastify"
import styles from "./inner-layout.module.scss"
import ModalWrapper from "../../common/modal/modal-wrapper/modal-wrapper"

const InnerLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleInnerQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={styles.wrapper}>
      {/* Every layout needs this to be able to display reusable modal windows (e.g. alertModal) */}
      <ModalWrapper />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div className={styles.layout}>
        <div className={styles.top}></div>
        <div className={styles.container}>{children}</div>
      </div>
      <ToastContainer />
    </div>
  )
}

InnerLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default InnerLayout
